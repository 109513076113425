import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import brLocale from 'date-fns/locale/pt-BR';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import toastError from "../../errors/toastError";
import { Box, Chip } from "@material-ui/core";
import { TagsFilter } from "../../components/TagsFilter";
import { UsersFilter } from "../../components/UsersFilter";
import { Stack } from "@mui/material";
import useTickets from "../../hooks/useTickets";
import ContactTag from "../../components/ContactTag";
import { format, parseISO, subDays } from "date-fns";
import { Link } from "react-router-dom";


const reducer = (state, action) => {
  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload;

    newTickets.forEach(ticket => {
      const ticketIndex = state.findIndex(t => t.id === ticket.id);
      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
        if (ticket.unreadMessages > 0) {
          state.unshift(state.splice(ticketIndex, 1)[0]);
        }
      } else {
        state.push(ticket);
      }
    });

    return [...state];
  }

  if (action.type === "RESET_UNREAD") {
    const ticketId = action.payload;

    const ticketIndex = state.findIndex(t => t.id === ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].unreadMessages = 0;
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex(t => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_TAGS") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex(t => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].tags = ticket.tags;
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex(t => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
      state.unshift(state.splice(ticketIndex, 1)[0]);
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_CONTACT") {
    const contact = action.payload;
    const ticketIndex = state.findIndex(t => t.contactId === contact.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].contact = contact;
    }
    return [...state];
  }

  if (action.type === "DELETE_TICKET") {
    const ticketId = action.payload;
    const ticketIndex = state.findIndex(t => t.id === ticketId);
    if (ticketIndex !== -1) {
      state.splice(ticketIndex, 1);
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const TagsReport = () => {
  const classes = useStyles();

  const [pageNumber, setPageNumber] = useState(1);
  const [ticketsList, dispatch] = useReducer(reducer, []);
  const [searchParam, setSearchParam] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [initialDate, setInitialDate] = useState(subDays(new Date(), 30));
  const [finalDate, setFinalDate] = useState(null);

  const { tickets, hasMore, loading } = useTickets({
    pageNumber,
    searchParam,
    tags: JSON.stringify(selectedTags),
    users: JSON.stringify(selectedUsers),
    date: initialDate,
    finalDate,
  });

  //Alterei o hook, acrescentei o selectUsers
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);

    if (selectedTags.length === 0 || selectedUsers.length === 0)  return;
    
    console.log('selectedTags', selectedTags);
    console.log('selectedUsers', selectedUsers);

    dispatch({
      type: "LOAD_TICKETS",
      payload: tickets,
    });

    console.log('tickets', tickets);

  }, [selectedTags, selectedUsers, tickets]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    // if (!hasMore || loading) return;
    // const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    // if (scrollHeight - (scrollTop + 100) < clientHeight) {
    //   loadMore();
    // }
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
  };

  const handleSelectedUsers = (selecteds) => {
    const users = selecteds.map((t) => t.id);
    setSelectedUsers(users);
  };

  return (
    <MainContainer>
      <MainHeader >
        <Title >Relatório de tags</Title>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >

        <Stack direction={'row'} sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', }}>
          <TagsFilter onFiltered={handleSelectedTags} />
          <UsersFilter onFiltered={handleSelectedUsers} />
          <Box style={{ padding: 10, minWidth: '35ch' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
              <DatePicker
                value={initialDate}
                onChange={(newValue) => { setInitialDate(newValue) }}
                label="Data inicial"
                renderInput={(params) => <TextField variant="outlined" size="small" fullWidth {...params} sx={{ width: '20ch' }} />}

              />
            </LocalizationProvider>
          </Box>
          <Box style={{ padding: 10, minWidth: '35ch' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
              <DatePicker
                value={finalDate}
                onChange={(newValue) => { setFinalDate(newValue) }}
                label="Data final"
                renderInput={(params) => <TextField
                  variant="outlined"
                  size="small"
                  fullWidth {...params} sx={{ width: '20ch', }} />}
              />
            </LocalizationProvider>
          </Box>

        </Stack>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Ticket id</TableCell>
              <TableCell align="center">Ticket status</TableCell>
              <TableCell align="center">Contato</TableCell>
              <TableCell align="center">Atendente</TableCell>
              <TableCell align="center">Tag</TableCell>
              <TableCell align="center">Data atendimento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {/* Alterei o estado para 'tickets' em vez de 'ticketsList' */}
              {tickets.map((ticket) => {
                const { contact, tags, user } = ticket;
                return (
                  <TableRow key={ticket.id}>
                    <TableCell align="center">
                      <Link to={`/tickets/${ticket?.id}`}>{ticket?.id}</Link>
                    </TableCell>
                    <TableCell align="center">{ticket?.status}</TableCell>
                    <TableCell align="center">{contact?.name}</TableCell>
                    <TableCell align="center">{user?.name}</TableCell>
                    <TableCell align="center">
                      {tags.map((tag) => (
                        <ContactTag tag={tag} key={tag?.id} />
                      ))}
                    </TableCell>
                    <TableCell align="center"> {format(parseISO(ticket?.createdAt), "dd/MM/yyyy")}</TableCell>
                    {/* <TableCell align="center">
                      <Chip
                        variant="outlined"
                        style={{
                          backgroundColor: tag.color,
                          textShadow: "1px 1px 1px #000",
                          color: "white",
                        }}
                        label={tag.name}
                        size="small"
                      />
                    </TableCell>
                    <TableCell align="center">{tag.ticketsCount}</TableCell>
                    <TableCell align="center">{tag.ticketsCount}</TableCell>
                    <TableCell align="center">{tag.ticketsCount}</TableCell>
                    <TableCell align="center">
                      <IconButton size="small" >
                        <EditIcon />
                      </IconButton>
  
                      <IconButton
                        size="small"
  
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                )
              })}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default TagsReport;
