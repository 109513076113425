import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Box,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    makeStyles,
    Paper,
    Typography,
    Button,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";

import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import ChatMessageInput from "../ChatMessageInput";

import { format, parseISO, isSameDay } from "date-fns";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { getBackendUrl } from "../../config";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        flex: 1,
        overflow: "hidden",
        borderRadius: 0,
        height: "100%",
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    },
    messageList: {
        position: "relative",
        overflowY: "auto",
        height: "100%",
        ...theme.scrollbarStyles,
        //backgroundColor: "#eee",
        // paddingTop: "5px",
    },
    inputArea: {
        position: "relative",
        height: "auto",
        border: "1px solid #ccc"
    },
    input: {
        padding: "20px",
    },
    buttonSend: {
        margin: theme.spacing(1),
    },
    boxLeft: {
        padding: "10px 10px 5px",
        margin: "10px",
        position: "relative",
        backgroundColor: "#DCE8FD",
        maxWidth: 300,
        borderRadius: 10,
        borderBottomLeftRadius: 0,
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    boxRight: {
        padding: "10px 10px 5px",
        margin: "10px 10px 10px auto",
        position: "relative",
        backgroundColor: "#DCFDC6",
        textAlign: "right",
        maxWidth: 300,
        borderRadius: 10,
        borderBottomRightRadius: 0,
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
}));

export default function ChatMessages({
    chat,
    messages,
    handleLoadMore,
    scrollToBottomRef,
    pageInfo,
    loading,
}) {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const baseRef = useRef();

    const scrollToBottom = () => {
        if (baseRef.current) {
            baseRef.current.scrollIntoView({});
        }
    };

    const unreadMessages = (chat) => {
        if (chat && chat.users) {
            const currentUser = chat.users.find((u) => u.userId === user.id);
            if (currentUser) {
                return currentUser.unreads > 0;
            }
        }

        if (user.profile === 'admin') {
            for (let i = 0; i < chat.users.length; i++) {
                if (chat.users[i].unreads > 0) {
                    return 1;
                }
                return 0;
            }
        }

        return 0;
    };

    useEffect(() => {
        if (unreadMessages(chat) > 0) {
            try {
                api.post(`/chats/${chat.id}/read`, { userId: user.id });
            } catch (err) {
                toastError(err);
            }
        }
        scrollToBottomRef.current = scrollToBottom;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleScroll = (e) => {
        const { scrollTop } = e.currentTarget;
        if (!pageInfo.hasMore || loading) return;
        if (scrollTop < 600) {
            handleLoadMore();
        }
    };

    const downloadFile = async (filePath) => {
        try {
            // Faz uma requisição GET para a rota de download com o caminho do arquivo como parâmetro
            const response = await api.get(`/files/download/${filePath}`, {
                responseType: 'blob',
            });

            // Verifica se a resposta foi bem-sucedida
            if (response) {
                // Obtém o conteúdo do arquivo como um blob
                const blob = await response.blob();

                // Cria um URL temporário para o blob
                const url = window.URL.createObjectURL(blob);

                // Cria um link <a> para fazer o download do arquivo
                const link = document.createElement('a');
                link.href = url;
                link.download = filePath.split('/').pop(); // Define o nome do arquivo como o último segmento do caminho
                link.click();

                // Limpa o URL temporário
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Falha ao baixar o arquivo:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Erro ao baixar o arquivo:', error);
        }
    };

    let url = getBackendUrl();

    return (
        <Paper className={classes.mainContainer}>
            <div onScroll={handleScroll} className={classes.messageList}>
                {Array.isArray(messages) &&
                    messages.map((item, key) => {
                        if (item.userId === user.id) {
                            return (
                                <Box key={key} className={classes.boxRight}>
                                    <Typography variant="subtitle2">
                                        {item.user.name}
                                    </Typography>
                                    {/*<div>
                                        <Typography variant="body1">
                                            {item.message}
                                        </Typography>
                            </div>*/}
                                    {item.media ? (

                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                target="_blank"
                                                href={`${url}/public/${item.media}`}
                                            //download={item.mediaName}
                                            >
                                                {item.mediaName}
                                            </Button>
                                            <Typography variant="subtitle2">
                                                Clique para baixar
                                            </Typography>
                                        </div>

                                    ) : (
                                        <div>
                                            <Typography variant="body1">
                                                {item.message}
                                            </Typography>
                                        </div>
                                    )}
                                    <Typography variant="caption" display="block">
                                        {isSameDay(parseISO(item.createdAt), new Date()) ? (
                                            <>{format(parseISO(item.createdAt), "HH:mm")}</>
                                        ) : (
                                            <>{format(parseISO(item.createdAt), "dd/MM/yyyy HH:mm")}</>
                                        )}
                                    </Typography>
                                </Box>
                            );
                        } else {
                            return (
                                <Box key={key} className={classes.boxLeft}>
                                    <Typography variant="subtitle2">
                                        {item.user.name}
                                    </Typography>
                                    {/* <div>
                                        <Typography variant="body1">
                                            {item.message}
                                        </Typography>
                            </div>*/}
                                    {item.media ? (

                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                target="_blank"
                                                href={`${url}/public/${item.media}`}
                                            //download={item.mediaName}
                                            >
                                                {item.mediaName}
                                            </Button>
                                            <Typography variant="subtitle2">
                                                Clique para baixar
                                            </Typography>
                                        </div>

                                    ) : (
                                        <div>
                                            <Typography variant="body1">
                                                {item.message}
                                            </Typography>
                                        </div>
                                    )}
                                    <Typography variant="caption" display="block">
                                        {isSameDay(parseISO(item.createdAt), new Date()) ? (
                                            <>{format(parseISO(item.createdAt), "HH:mm")}</>
                                        ) : (
                                            <>{format(parseISO(item.createdAt), "dd/MM/yyyy HH:mm")}</>
                                        )}
                                    </Typography>
                                </Box>
                            );
                        }
                    })}
                <div ref={baseRef}></div>
            </div>
            <div className={classes.inputArea}>
                <ChatMessageInput chat={chat} />
            </div>
        </Paper>
    );
}
